$('.document-link').on('click', (e) => {
    const $target = $(e.target);
    const documentLink = $target.parents('.document-link') || $target;
    if (!documentLink.hasClass('selected')) {
        loadDocument(documentLink);
        updateState(documentLink);
    };
});

$('.treegrid').treegrid({
    'initialState': 'collapsed',
    'saveState': false,
    'expanderExpandedClass': 'fa fa-caret-down',
    'expanderCollapsedClass': 'fa fa-caret-right'
});

$('.treegrid tr[data-treegrid-expanded=true]').each(function() {
    $(this).treegrid('expand');
});

$('.treegrid-expand-all').on('click', function() {
    return toggleAll('expandAll')
});

$('.treegrid-collapse-all').on('click', function() {
    return toggleAll('collapseAll')
});

$(document).ready(function() {
    const documentAnchor = $('#legal-library').data('selectedDocument');
    let documentEl = $(`*[data-full-anchor="${documentAnchor}"]`);

    if (documentEl.length) {
        clickDocumentEl(documentEl);
    } else if (documentAnchor) {
        const documentEl = $(`*[data-anchor="${documentAnchor}"]`);
        if (documentEl.length) {
            clickDocumentEl(documentEl);
        } else {
            const version = documentAnchor.match(/(0[0-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{2}$)/);
            if (version) {
                const docWithSameVersion = $(`*[data-full-anchor$="_${version[0]}"]`);
                if (docWithSameVersion.length) {
                    showDocumentList(docWithSameVersion);
                } else {
                    showNotFound();
                }
            } else {
                showNotFound();
            }
        }
    }
});

function showDocumentList(documents) {
    documents.toArray().forEach(appendDocLinkToList);
    $('#suggested-list').show();
    $('#services-list').hide();
    $('#not-found-info').hide();
}

function showNotFound() {
    $('#services-list').hide();
    $('#not-found-info').show();
}

function appendDocLinkToList(item) {
    const suggestedList = $('#suggested-list ul');
    let $item = $(item);
    let itemLink = $('<a>');
    itemLink.attr('href', $item.data('fullAnchor'));
    itemLink.html($item.text());
    $('<li>').html(itemLink).appendTo(suggestedList);
}

function clickDocumentEl(documentEl) {
    clickExpander(documentEl);
    documentEl.find('.document-link-label').click();
}

function clickExpander(documentEl) {
    const expander = getExpander(documentEl);
    const parentClasses = expander.parents('tr').attr('class');
    expander.click();
    if (parentClasses.includes('treegrid-paren')) {
        clickExpander(expander);
    }
}

function getExpander(element) {
    const parentClass = element.parents('tr').attr('class').split(' ').find((klass) => {
        return klass.includes('treegrid-parent');
    })?.replace('treegrid-parent-', '');
    return $(`tr[class*="${parentClass}"]:first .treegrid-expander`);
}

function loadDocument(documentLink) {
    const url = documentLink.data('pdfUrl');
    if (!url) return;

    $('.document-link').removeClass('selected');
    documentLink.addClass('selected');
    $('#main-container').html(`<iframe src="${url}" style='width: 100%;height: 100%;border: none;'></iframe>`);
}

function toggleAll(method) {
    let element;
    element = $(this).data('treegrid-element-id');
    if (element === false) {
        element = $('.treegrid');
    } else {
        element = '#' + element;
    }
    return $(element).treegrid(method);
}

function updateState(documentLink) {
    const documentAnchor = documentLink.data('anchor');
    const selectedAnchor = $('#legal-library').data('selectedDocument');
    if (!documentAnchor) return;

    let newUrl;

    if (selectedAnchor.length) {
        newUrl = window.location.href.replace(/\/[^\/]*$/, `/${documentAnchor}`);
    } else {
        newUrl = window.location.href + `/${documentAnchor}`
    }

    $('#legal-library').data('selected-document', documentAnchor);
    history.pushState({}, null, newUrl.replaceAll(/(^|[^:])\/{2,}/g, '$1/'));
}
