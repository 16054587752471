import 'bootstrap/dist/js/bootstrap.js';
import 'select2/dist/js/select2.full';
import 'jquery-treegrid/js/jquery.treegrid';
import 'jquery-treegrid/js/jquery.treegrid.bootstrap3';
import 'jquery.hotkeys/jquery.hotkeys';

// CSS loader
import "jquery-treegrid/css/jquery.treegrid.css";

import 'javascripts/legal_library';
